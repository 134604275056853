@import 'styles/theme';

.Slice404 {
  position: relative;
  height: calc(vh(100) - theme($navHeight, 'mobile'));

  @include from('lg') {
    height: calc(vh(100) - theme($navHeight, 'desktop'));
  }

  .content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    align-items: center;
  }

  .box {
    grid-column: 1/-1;
    text-align: center;
    background: theme($colors, 'white');
    border-radius: theme($borderRadius, 'default');
    box-shadow: theme($shadows, 'default');
    padding: theme($spacings, 40) theme($spacings, 32) theme($spacings, 32);

    @include from('md') {
      grid-column: 5/-5;
    }
  }

  .title {
    margin-bottom: theme($spacings, 8);
  }

  .cta {
    margin-top: theme($spacings, 32);
  }

  &.withBanner {
    height: calc(
      vh(100) - theme($navHeight, 'desktopBanner') - theme($navHeight, 'mobile')
    );

    @include from('md') {
      height: calc(
        vh(100) - theme($navHeight, 'desktopBanner') - theme($navHeight, 'desktop')
      );
    }
  }
}
